import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
import emailjs from "emailjs-com";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { feedback: "", name: "", email: "", subject: "" };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  }

  sendEmail(event) {
    alert("Thank you for your message!");
    this.setState({
      feedback: "",
      name: "",
      email: "",
      subject: "",
    });
    event.preventDefault();

    emailjs
      .sendForm(
        "service_rbg1dhq",
        "template_st3nj4b",
        event.target,
        "user_51jnF8WUkQOGhjcqxuzrK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const email = this.props.data.email;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const message = this.props.data.contactmessage;

    return (
      <section className="container-fluid" id="contact">
        <div className="container">
          <Fade bottom duration={1000}>
            <div className="row section-head">
              <div className="two columns header-col">
                <h1>
                  <span>Get In Touch.</span>
                </h1>
              </div>

              <div className="ten columns">
                <p className="lead">{message}</p>
              </div>
            </div>
          </Fade>

          <div className="row ">
            <Slide left duration={1000}>
              <div className="eight columns ">
                <form action="" method="" onSubmit={this.sendEmail}>
                  <fieldset>
                    <div>
                      <label htmlFor="contactName">
                        Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        size="35"
                        id="name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="contactEmail">
                        Email <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        size="25"
                        id="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="contactSubject">Subject</label>
                      <input
                        type="text"
                        size="25"
                        id="subject"
                        name="subject"
                        value={this.state.subject}
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="contactMessage">
                        Message <span className="required">*</span>
                      </label>
                      <textarea
                        cols="50"
                        rows="10"
                        id="feedback"
                        name="feedback"
                        value={this.state.feedback}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>

                    <div>
                      <button className="submit">Submit</button>

                      <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                      </span>
                    </div>
                  </fieldset>
                </form>

                <div id="message-warning"> Error boy</div>
                <div id="message-success">
                  <i className="fa fa-check"></i>Your message was sent, thank
                  you!
                  <br />
                </div>
              </div>
            </Slide>

            <Slide right duration={1000}>
              <aside className="four columns footer-widgets">
                <div>
                  <h4 className="addressLabel">Info :</h4>
                  <p className="address">
                    {name}, {email}
                    <br />
                    {city}, {state}
                    <br />
                  </p>
                </div>
              </aside>
            </Slide>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
