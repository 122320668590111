import React, { Component } from "react";
import Fade from "react-reveal";

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCampsite: null,
    };
  }

  onCampsiteSelect(campsite) {
    console.log("clicked");
    this.setState({ selectedCampsite: campsite });
  }

  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map((projects) => {
      let projectImage = "images/portfolio/" + projects.image;
      return (
        <div className="columns portfolio-item">
          <div className="item-wrap">
            <a href={projects.url}>
              <i>
                <img alt={projects.title} src={projectImage} />
              </i>
            </a>
            <div style={{ textAlign: "center" }}>{projects.title}</div>
          </div>
        </div>
      );
    });

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check Out Some of My Works.</h1>

              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
